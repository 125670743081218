import React, { useRef, useState } from 'react';
import { InlineTextButton } from '../../../../components';
import { FormattedMessage } from '../../../../util/reactIntl';
import { truncate } from 'lodash';

import css from './ExpandableText.module.css';


const truncatedText = (text, length) => {
  return truncate(text, {
    length,

    // Allow truncated text end only in specific characters. This will
    // make the truncated text shorter than the length if the original
    // text has to be shortened and the substring ends in a separator.
    //
    // This ensures that the final text doesn't get cut in the middle
    // of a word.
    separator: /\s|,|\.|:|;/,
    omission: '…',
  });
};

const ExpandableText = ({ className, text, maxLength }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const textRef = useRef(null);

  const shortenedText = truncatedText(text, maxLength);
  const isTextTruncated = text !== shortenedText;

  const toggleExpand = () => {
    setIsExpanded(prev => !prev);
  };

  return (
    <p className={className} ref={textRef}>
      {isExpanded ? text : shortenedText}
      {isTextTruncated && (
        <InlineTextButton
          rootClassName={css.button}
          onClick={toggleExpand}
          aria-expanded={isExpanded}
        >
          <FormattedMessage
            id={
              isExpanded
                ? 'ExpandableText.showLessText'
                : 'ExpandableText.showFullText'
            }
          />
        </InlineTextButton>
      )}
    </p>
  );
};

export default ExpandableText;
